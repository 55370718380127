// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCMy4XvbBbiZo9RyHzoPNiiEVOfBFXcYW8",
  authDomain: "messagesearch-93078.firebaseapp.com",
  projectId: "messagesearch-93078",
  storageBucket: "messagesearch-93078.appspot.com",
  messagingSenderId: "1025755231357",
  appId: "1:1025755231357:web:28ebc4f709003af8c1ed14",
  measurementId: "G-7YV3ECNZZL",
};
